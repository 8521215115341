<template>
  <div class="community">
    <van-notice-bar
      style="position: fixed; top: 0; width: 100%; z-index: 99"
      left-icon="volume-o"
      :speed="30"
      text="如当前小区暂未开通百事通，可联系客服开通（057189906536、18757958624）"
    />
    <div class="tab">
      <div
        :class="{ active: active == index }"
        v-for="(item, index) in tab"
        :key="index"
        @click="checkTab(item, index)"
      >
        {{ item }}
      </div>
    </div>
    <div style="margin-top: 4.6rem; margin-bottom: 1rem">
      <div v-if="tabText == '基础服务' || tabText == '全部'">
        <div class="bigTitle" v-if="listOne.length != 0">基础服务</div>
        <div v-for="(val, i) in listOne" :key="i">
          <div class="community-box" v-if="!val.isHidden">
            <div class="title">
              {{ val.servingCategoryName }}
            </div>
            <div v-if="!val.isFixedCategory && val.siteServingInfoList">
              <div
                v-for="(
                  value, num
                ) in val.siteServingInfoList[0].servingInfoPhone.split(',')"
                :key="num"
              >
                <div class="text">
                  <div class="service"></div>
                  <div class="hotline" @click="callTel(value, val)">
                    {{ value }}
                  </div>
                </div>
              </div>
            </div>
            <div v-if="val.isFixedCategory">
              <div class="text1">
                <div class="service">
                  {{ val.siteServingInfoList[0].servingInfoName }}
                </div>
                <div
                  v-if="val.siteServingInfoList[0].servingInfoPhone"
                  class="hotline"
                  @click="showTel(val.siteServingInfoList[0])"
                >
                  拨打热线
                </div>
              </div>
            </div>
          </div>
        </div>
        <no-data v-if="listOne.length == 0 && tabText != '全部'"></no-data>
      </div>
      <div v-if="tabText == '物业服务' || tabText == '全部'">
        <div class="bigTitle" v-if="listTwo.length != 0">物业服务</div>
        <div v-for="(val, i) in listTwo" :key="i">
          <div class="community-box" v-if="!val.isHidden">
            <div class="title">
              {{ val.servingCategoryName }}
            </div>
            <div v-if="!val.isFixedCategory && val.siteServingInfoList">
              <div
                v-for="(
                  value, num
                ) in val.siteServingInfoList[0].servingInfoPhone.split(',')"
                :key="num"
              >
                <div class="text">
                  <div class="service"></div>
                  <div class="hotline" @click="callTel(value, val)">
                    {{ value }}
                  </div>
                </div>
              </div>
            </div>
            <div v-if="val.isFixedCategory">
              <div class="text1">
                <div class="service">
                  {{ val.siteServingInfoList[0].servingInfoName }}
                </div>
                <div
                  v-if="val.siteServingInfoList[0].servingInfoPhone"
                  class="hotline"
                  @click="showTel(val.siteServingInfoList[0])"
                >
                  拨打热线
                </div>
              </div>
            </div>
          </div>
        </div>
        <no-data v-if="listTwo.length == 0 && tabText != '全部'"></no-data>
      </div>
      <div v-if="tabText == '商业服务' || tabText == '全部'">
        <div class="bigTitle" v-if="listThree.length != 0">商业服务</div>
        <div v-for="(val, i) in listThree" :key="i">
          <div class="community-box" v-if="!val.isHidden">
            <div class="title">
              {{ val.servingCategoryName }}
            </div>
            <div v-if="!val.isFixedCategory && val.servingInfoPhone">
              <div
                v-for="(
                  value, num
                ) in val.siteServingInfoList[0].servingInfoPhone.split(',')"
                :key="num"
              >
                <div class="text">
                  <div class="service"></div>
                  <div class="hotline" @click="callTel(value, val)">
                    {{ value }}
                  </div>
                </div>
              </div>
            </div>
            <div v-if="val.isFixedCategory">
              <div class="text1">
                <div class="service">
                  {{ val.siteServingInfoList[0].servingInfoName }}
                </div>
                <div
                  v-if="val.siteServingInfoList[0].servingInfoPhone"
                  class="hotline"
                  @click="showTel(val.siteServingInfoList[0])"
                >
                  拨打热线
                </div>
              </div>
            </div>
          </div>
        </div>
        <no-data v-if="listThree.length == 0 && tabText != '全部'"></no-data>
      </div>
    </div>
    <van-popup
      v-model="show"
      round
      position="bottom"
      :style="{ height: '10.3125rem' }"
    >
      <div
        class="tel"
        v-for="(value, i) in tel"
        :key="i"
        @click="callPhoe(value)"
      >
        {{ value }}
      </div>
      <div class="cancel" @click="show = false">取消</div>
    </van-popup>
    <no-data v-if="list.length == 0 && tabText == '全部'"></no-data>
  </div>
</template>
<script>
import api from "@api";
import noData from "../../components/no-data.vue";
export default {
  components: { noData },
  data() {
    return {
      siteId: this.$route.query.siteId,
      active: 0,
      tab: ["全部", "基础服务", "物业服务", "商业服务"],
      tabText: "全部",
      list: [],
      listOne: [],
      listTwo: [],
      listThree: [],
      show: false,
      tel: [],
      userInfoId: "", // 用户id
      servingInfoId: "" // 服务id
    };
  },
  created() {
    let roleData = localStorage.getItem("roleData");
    this.userInfoId = JSON.parse(roleData).userPO.id;
    this.init();
    this.getList();
  },
  methods: {
    // tab切换
    checkTab(val, i) {
      this.tabText = val;
      this.active = i;
      this.getList();
    },
    // 初始化数据
    getList() {
      api.siteServingPage({ siteId: this.siteId }).then(res => {
        if (res.meta.code == 200) {
          this.list = res.response;
          let listOne = [];
          let listTwo = [];
          let listThree = [];
          res.response.forEach(res => {
            if (
              res.bigServingCategory == "基础服务" &&
              res.siteServingInfoList
            ) {
              listOne.push(...res.siteServingInfoList);
            } else if (
              res.bigServingCategory == "基础服务" &&
              !res.isFixedCategory &&
              !res.isHidden
            ) {
              listOne.push({
                isFixedCategory: false,
                isHidden: false,
                servingCategoryName: res.servingCategoryName
              });
            }
          });
          res.response.forEach(res => {
            if (
              res.bigServingCategory == "物业服务" &&
              res.siteServingInfoList
            ) {
              listTwo.push(...res.siteServingInfoList);
            } else if (
              res.bigServingCategory == "物业服务" &&
              !res.isFixedCategory &&
              !res.isHidden
            ) {
              listTwo.push({
                isFixedCategory: false,
                isHidden: false,
                servingCategoryName: res.servingCategoryName
              });
            }
          });
          res.response.forEach(res => {
            if (
              res.bigServingCategory == "商业服务" &&
              res.siteServingInfoList
            ) {
              listThree.push(...res.siteServingInfoList);
            } else if (
              res.bigServingCategory == "商业服务" &&
              !res.isFixedCategory &&
              !res.isHidden
            ) {
              listThree.push({
                isFixedCategory: false,
                isHidden: false,
                servingCategoryName: res.servingCategoryName
              });
            }
          });
          this.listOne = listOne;
          this.listTwo = listTwo;
          this.listThree = listThree;
          listOne = [];
          listTwo = [];
          listThree = [];
          console.log(this.listOne, "this.listOne");
          console.log(this.listTwo, "this.listTwo");
          console.log(this.listThree);
        }
      });
    },
    // 计算进入页面次数
    init() {
      api.visitTimes({
        siteId: this.siteId,
        userInfoId: this.userInfoId
      });
    },
    // 固定类别拨打电话
    callTel(val, obj) {
      this.servingInfoId = obj.servingInfoId;
      this.callPhoe(val);
    },
    // 展示号码
    showTel(val) {
      this.tel = val.servingInfoPhone.split(",");
      this.servingInfoId = val.servingInfoId;
      if (this.tel.length > 1) {
        this.show = true;
      } else {
        this.callPhoe(this.tel[0]);
      }
    },
    // 拨打电话
    callPhoe(tel) {
      this.show = false;
      api
        .addPhoneTimes({
          siteId: this.siteId,
          servingInfoId: this.servingInfoId
        })
        .then(res => {
          if (res.meta.code == 200) {
            window.location.href = `tel://${tel}`;
          }
        });
    }
  }
};
</script>
<style lang="less" scoped>
.community {
  width: 100vw;
  height: 100vh;
  background-color: #fbfcff;
  box-sizing: border-box;
  color: #333;
  /deep/.van-notice-bar {
    height: 2rem;
  }
  .tab {
    position: fixed;
    top: 2rem;
    z-index: 99;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 2.5rem;
    background-color: #fff;
    .active {
      position: relative;
      &::after {
        position: absolute;
        left: 50%;
        bottom: -0.625rem;
        transform: translateX(-50%);
        width: 1rem;
        height: 0.25rem;
        border-radius: 0.125rem;
        background-color: #336df3;
        content: '';
      }
    }
  }
  .bigTitle {
    height: 2.5rem;
    padding-left: 0.9375rem;
    line-height: 2.5rem;
    font-size: 1rem;
    font-weight: 500;
    color: #000000;
  }
  &-box {
    overflow: hidden;
    margin: 0 0.625rem 0.625rem;
    padding: 0 0.9375rem;
    background: #ffffff;
    box-shadow: 0 0.3125rem 1.5625rem 0 rgba(188, 188, 188, 0.3);
    border-radius: 0.625rem;
    font-size: 0.875rem;
    .title {
      // position: relative;
      // padding-left: 0.75rem;
      height: 2.5rem;
      line-height: 2.55rem;
      font-size: 0.9375rem;
      font-weight: 400;
      // &::before {
      //   position: absolute;
      //   left: 0;
      //   top: 50%;
      //   transform: translateY(-50%);
      //   width: 0.3125rem;
      //   height: 1.0625rem;
      //   background: #336df3;
      //   border-radius: 0.125rem;
      //   content: '';
      // }
    }
    .text {
      display: flex;
      justify-content: space-between;
      height: 2.5rem;
      border-top: 0.0625rem solid #f6f6fa;
      line-height: 2.5rem;
      &:nth-child(n + 2) {
        justify-content: flex-end;
      }
      .service {
        width: 11.25rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .hotline {
        font-weight: 400;
        color: #336df3;
      }
    }
    .text1 {
      display: flex;
      justify-content: space-between;
      height: 2.5rem;
      border-top: 0.0625rem solid #f6f6fa;
      line-height: 2.5rem;
      .service {
        width: 11.25rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 0.8125rem;
        font-weight: 400;
        color: #666666;
      }
      .hotline {
        font-size: 0.875rem;
        font-weight: 400;
        color: #336df3;
      }
    }
  }
  .tel {
    text-align: center;
    height: 3.4375rem;
    border-bottom: 0.0625rem solid #f6f6fa;
    line-height: 3.4375rem;
    font-weight: 400;
    font-size: 1rem;
    color: #336df3;
  }
  .cancel {
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    height: 3.4375rem;
    line-height: 3.4375rem;
    background-color: #fff;
    z-index: 999;
    font-size: 1rem;
    color: #666;
  }
}
</style>
